import React from 'react';
import MainLayout from '../components/MainLayout/main-layout';

export default function PageNotFoundPage() {
  return(
    <MainLayout>
      <div>
        404
      </div>
    </MainLayout>
  );
}
